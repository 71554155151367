<template>
  <v-container fluid>
    <div class="form-container">
      <form
        novalidate
        class="supplier-role-selection-form md-layout"
        @submit.prevent="save"
      >
        <md-card
          class="supplier-role-container"
        >
          <md-card-header>
            <div class="md-title">Supplier Role Selection</div>
          </md-card-header>

          <md-card-content>
            <div class="md-layout-item md-small-size-100">
              <div>
                <span class="role-name">Are you a Company?</span> 
                <v-radio-group
                  v-model="model.isCompany"
                  row
                >
                  <span class="radio-label">Yes</span>
                  <v-radio
                    color="#47C0BE"
                    :value="true"
                  >
                    </v-radio>
                  <span class="radio-label radio-no-label">No</span>
                  <v-radio
                    color="#47C0BE"
                    :value="false"
                  >
                    </v-radio>
                </v-radio-group>
              </div>

              <h4 class="role-name">Select supplier roles</h4>
              <hr class="hr-line"/>

              <div v-for="role in supplierRoles" v-bind:key="role.name" class="list_color">
                <md-checkbox
                  data-cy="supplier-roles-checkbox"
                  v-model="model.supplierRoles"
                  v-bind:value="role.id"
                  > <span class="role-name"> {{ role.display_name }}</span></md-checkbox
                >
              </div>
            </div>
          </md-card-content>

          <md-card-actions class="save-btn-container">
            <md-button
              type="submit"
              class="md-raised md-primary save-btn"
              data-cy="save"
              :disabled="saving"
              >Save</md-button
            >
          </md-card-actions>
        </md-card>
      </form>
    </div>
    <background-image />
  </v-container>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import auth from "../../auth";
import notification from "../../notification";
import restAdapter from "../../restAdapter";
import BackgroundImage from "../../assets/background-images/BackgroundImage.vue"

export default {
  name: "supplierRoleSelection",
  components:{
    BackgroundImage,
  },
  data: () => ({
    supplierRoles: [],
    model: {
      isCompany: false,
      supplierRoles: [],
      email: null,
    },
    saving: false,
  }),
  methods: {
    clearForm() {
      this.model.isCompany = null;
      this.model.supplierRoles = [];
    },
    save() {
      this.saving = true;

      if (
        Array.isArray(this.model.supplierRoles) &&
        this.model.supplierRoles.length < 1
      ) {
        this.saving = false;
        notification.errors({
          supplierRoles: ["Select at least one supplier role"],
        });
        return;
      }

      restAdapter
        .post("/api/login/select_supplier_role", {
          is_company: this.model.isCompany,
          id: this.model.supplierRoles,
        })
        .then((response) => {
          const userData = {
            isCompany: this.model.isCompany,
            subRoles: this.model.supplierRoles,
          };
          auth.updateAuthRoles(userData);
          this.clearForm();
          notification.success("Added Supplier Roles Successfully");
          this.$router.push({ path: "/app" });
        })
        .catch((error) => {
          this.saving = false;
          if (
            error.response &&
            error.response.status == 400 &&
            error.response.data &&
            error.response.data.error
          ) {
            notification.errors(error.response.data.error);
          } else {
            notification.error("Something went wrong, please try again later.");
          }
        });
    },
  },
  mounted() {
    this.model.isCompany = auth.user.isCompany;
    this.model.supplierRoles = auth.user.subRoles || [];

    restAdapter.get("/api/login/display_supplier_role").then((response) => {
      this.supplierRoles = response.data.roles;
    });
  },
};
</script>

<style lang="scss" scoped>
.supplier-role-selection-form {
  background-color: transparent !important;
  padding-top: 10px;
  justify-content: center;
  padding-bottom: 40px;
}

@media only screen and (min-width: 900px) {
  .supplier-role-selection-form {
    padding-top: 80px;
  }
}


.md-title{
  color: $primaryDark;
  font-weight: 600;
  margin-bottom: 15px;
}

.supplier-role-container{
  background: $paginateText;
  box-shadow: 0px 13.5045px 25.8836px rgba(61, 77, 135, 0.1);
  border-radius: 7px;
  padding: 0px 50px 0px 50px;
  min-width: 25%;
}

.save-btn-container{
  justify-content: center;
  margin: 28px 0px 28px 0px;
}

.save-btn{
  background-color: $primaryDarkest !important;
  border-radius: 3px;
  text-transform: capitalize;
}

.list_color::v-deep .md-checkbox.md-theme-default.md-checked .md-checkbox-container {
    background-color: $popupWindow !important;
    border-radius: 4px !important;
}

.list_color::v-deep .md-checkbox.md-theme-default .md-checkbox-container {
    background-color: $popupWindow !important;
    border-color: transparent !important;
    border-radius: 4px !important;
}

.list_color::v-deep .md-checkbox.md-theme-default.md-checked .md-checkbox-container:after{
  border-color: $primaryDarkest !important;
}

.role-name{
  color: $primaryDark;
  font-weight: 600;
}

.hr-line{
  background: $primaryDarkest;
  height: 3px;
  margin: 10px 0px 15px 0px;
}

.radio-label{
  color: $primaryDark;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 600;
}

.radio-no-label{
  margin-left: 25px;
}

.form-container{
  max-height: calc(100vh - 24px);
  position: relative;
  z-index: 1;
  overflow: auto;
}

</style>
